import useApiInstances from './axios';

const useDirectDeposit = (jwt) => {
	const {apiBlockmate} = useApiInstances();

	const overview = async (depositId) => {
		const response = await apiBlockmate.get(`/v1/exchange/deposit/direct/${depositId}/overview`, {
			headers: {'Authorization': `Bearer ${jwt}`},
		});
		const data = response?.data;
		return {
			authChallenge: data?.challenge,
			supportedCurrencies: data?.supported_currencies,
		};
	};

	const authorize = async (depositId, address, signature, currency, network) => {
		const response = await apiBlockmate.post(
			`/v1/exchange/deposit/direct/${depositId}/authorize`,
			{address, signature, crypto_currency: currency, network},
			{
				headers: {'Authorization': `Bearer ${jwt}`}
			},
		);
		const data = response?.data;
		return {
			depositAddress: data?.deposit_address,
			cryptoAmount: data?.crypto_amount,
		};
	};

	const finish = async (depositId, transactionId) => {
		await apiBlockmate.post(
			`/v1/exchange/deposit/direct/${depositId}/finish`,
			{transaction_id: transactionId},
			{
				headers: {'Authorization': `Bearer ${jwt}`}
			},
		);
	};

	return {
		overview,
		authorize,
		finish,
	};
};

export default useDirectDeposit;
